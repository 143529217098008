    @import '~antd/dist/antd.css';

    .main-header {
        font-style: normal;
        font-family: IBM Plex Sans;
        position: fixed !important;
        width: 100%;
        background: var(--white-8am);
        padding: 0 16px 10px;
        z-index: 1000;
        gap: 1em;
        padding-top: 24px;

        .header-info {
            display: grid;
            grid-template-columns: .1fr 1fr .1fr;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            margin: 0 auto;
            gap: 0;

            .header-left {
                display: flex;
                align-items: center;
                gap: 1em;

                .header-logo {
                    cursor: pointer;

                    // display: flex;
                    // align-items: center;
                    // gap: 1em;
                    // cursor: pointer;
                    // font-weight: 700;
                    // font-size: 2.5em;
                    // color: var(--orange-8am);
                    // margin-bottom: .2em;
                    img {
                        width: 68.022px;
                        height: 20px;
                    }
                }

                .pages {
                    display: flex;
                    align-items: center;
                    gap: 1em;

                    .page-item {
                        // margin: 1em;
                        font-family: IBM Plex Sans;
                        padding: .75em 1em;
                        color: var(--black-8am);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        cursor: pointer;
                        transition: all 0.3s ease-in-out;
                        border-radius: 100px;
                        white-space: nowrap
                    }

                    .page-item:hover {
                        background-color: var(--lightgrey-8am);
                    }

                    .page-item.active {
                        color: var(--white-8am);
                        background-color: var(--black-8am);
                    }
                }
            }

            .header-content-input {
                position: relative;
                display: flex;
                align-items: center;
                margin: 0px 1vw;
                font-family: IBM Plex Sans;
                margin-right: 32px;

                .ant-select {
                    width: 100%;

                    .ant-select-selector {
                        height: 2.5em;
                        border-radius: 50px;
                        z-index: 3;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                        background-color: var(--lightgrey-8am);
                        border: none;
                        color: var(--black-8am);

                        display: flex;
                        align-items: center;
                        font-size: 1.25em;

                        .ant-select-selection-search {
                            display: flex;
                            align-items: center;

                            .ant-select-selection-search-input {
                                margin-left: 2.5vw;
                                margin-right: 2vw;
                                font-size: 15px;
                            }
                        }

                        .ant-select-selection-placeholder {
                            margin-left: 2.5vw;
                            margin-top: 0px;

                            .placeholder-select {
                                color: var(--8am-grey, #5A5A5A);
                                font-family: IBM Plex Sans;
                                font-size: 15px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 40px;
                                /* 266.667% */
                            }
                        }
                    }

                    .ant-select-arrow {
                        left: 1vw;
                    }

                    .ant-select-clear {
                        background-color: var(--lightgrey-8am);
                        right: 1vw;
                    }

                }
            }

            .header-right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 1vw;

                .menubtn {
                    background: linear-gradient(308.85deg, var(--main-color) 0%, var(--main-color) 100%);
                    border: none;
                    color: white;
                    transition: all 0.3s ease-in-out;
                }

                .menubtn:hover,
                .menubtn:focus {
                    animation: pulse 1s;
                    box-shadow: 0 0 0 2em transparent;
                    background: linear-gradient(308.85deg, var(--main-color) 0%, var(--main-color) 100%);
                }


                .user-infor {
                    display: flex;
                    align-items: center;
                    border-radius: 100px;
                    border: .1vw solid var(--lightgrey-8am);
                    padding: 10px 12px;
                    color: var(--black-8am);
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    white-space: nowrap;
                    font-family: IBM Plex Sans;
                    gap: 4px;

                    svg {
                        width: 24px;
                        height: 24px;
                    }

                    .avatar {
                        width: 24px;
                        height: 24px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .user-infor:hover {
                    background-color: var(--black-8am);
                    color: var(--white-8am);
                }
            }
        }

        .header-type-news {
            display: flex;
            align-items: center;
            margin: 0 auto;
            gap: 2em;

            .content {
                font-weight: 400;
                font-size: 1.25em;
                text-align: center;
                color: var(--text-small);
                transition: all 0.3s ease-in-out;
                border-bottom: 3px solid white;
                cursor: pointer;

            }

            .content.active {
                border-bottom: 3px solid var(--main-color);
                color: var(--main-color);
            }
        }
    }

    .main-header.hide {

        .header-left {
            .header-logo {
                img {}

                .logo-text {}
            }
        }

        .header-content-input {
            .search-input {}

            .search-input::placeholder {}

            .search-input:hover {}

            .search-input:focus {}

            .anticon {}

            .anticon-search {}

            .anticon-down {}


        }

        .header-right {
            .menubtn {}

            .menubtn:hover,
            .menubtn:focus {}


            .user-infor {
                .icon-group {
                    .anticon {}

                    .anticon.active {}


                }
            }
        }

        .header-type-news {
            .content {}

            .content.active {}
        }
    }

    .search-active {
        position: fixed;
        display: flex;
        align-items: center;
        gap: 1em;
        padding: 0px 1em;
        width: 100%;
        top: 0;
        z-index: 1000;
        background-color: var(--second-bg-color);

        .search-input-content {
            position: relative;
            display: flex;
            align-items: center;
            margin: .5em;
            width: 100%;

            .search-input {
                font-weight: 400;
                font-size: 1em;
                line-height: 24px;
                color: var(--main-color);
                background: var(--second-bg-color);
                border: .1vw solid var(--border-input);
                padding-left: 2em;
                border-radius: 10px;
            }

            .search-input::placeholder {
                color: var(--placeholder-text-input);
                font-size: .8em;
            }

            .search-input:hover {
                border: .1vw solid var(--main-color) !important;
            }

            .search-input:focus {
                border: .1vw solid var(--main-color) !important;
                box-shadow: 0 0 0 .1vw var(--main-color);
                caret-color: var(--main-color);
            }

            .ant-select {
                width: 100%;

                .ant-select-selector {
                    z-index: 3;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 24px;
                    color: #696969;

                    .ant-select-selection-search {
                        .ant-select-selection-search-input {
                            // margin-left: 10px;
                        }
                    }
                }


            }
        }

        .anticon-arrow-left {
            cursor: pointer;
        }
    }

    .search-active-responsive {
        position: sticky;
        display: flex;
        flex-direction: column;
        gap: 1em;
        padding: 0px 1em;
        width: 100%;
        height: 1000px;
        overflow-y: scroll;
        top: 0;
        z-index: 100000;
        background-color: var(--white-8am);

        // Ẩn đi scroll bar 
        &::-webkit-scrollbar {
            display: none;
        }


        .title {
            position: sticky;
            display: flex;
            justify-content: space-between;
            padding: 12px 0;

            .icon-close {
                display: flex;
                width: 24px;
                height: 24px;
                padding: 4px 0px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                color: var(--white-8am);
                border-radius: 100px;
                background: var(--8am-middlegrey, #86868B);
            }
        }

        .search-input-content {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;

            border-radius: 100px;

            .search-input {
                font-weight: 400;
                font-size: 1em;
                line-height: 24px;
                color: var(--main-color);
                background: var(--second-bg-color);
                border: .1vw solid var(--border-input);
                padding-left: 2em;
                border-radius: 10px;
            }

            .search-input::placeholder {
                color: var(--placeholder-text-input);
                font-size: .8em;
            }

            .search-input:hover {
                border: .1vw solid var(--main-color) !important;
            }

            .search-input:focus {
                border: .1vw solid var(--main-color) !important;
                box-shadow: 0 0 0 .1vw var(--main-color);
                caret-color: var(--main-color);
            }

            .ant-select {
                width: 100%;
                border-radius: 100px;

                .ant-select-selector {
                    z-index: 3;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #696969;
                    border-radius: 100px;
                    border: 1px solid var(--8am-lightgrey, #E9E9E9);
                    background: #E9E9E9;

                    .ant-select-selection-search {
                        .ant-select-selection-search-input {
                            margin-left: 20px;
                            font-size: 14px;
                        }
                    }

                    .ant-select-selection-placeholder {
                        font-size: 14px;
                        margin-left: 20px;

                    }

                    .ant-select-clear {
                        background-color: var(--lightgrey-8am);
                        right: 10px;
                        width: 24px !important;
                        height: 24px !important;
                    }
                }

                .ant-select-arrow {
                    left: 1vw;
                }



            }
        }

        .pages-responsive {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // gap: 5px;

            .page-item {
                color: var(--8am-black, #1D1D1F);
                font-family: IBM Plex Sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                /* 142.857% */
                cursor: pointer;
            }

            .line-break {
                width: 100%;
                height: 1px;
                background-color: var(--8am-lightgrey, #E9E9E9);
            }
        }
    }

    .container-menu {
        display: flex;
        flex-direction: column;
        gap: .5vw;
        padding: 48px 40px;
        padding-top: 20px;
        align-items: flex-start;
        gap: 10px;

        .title {
            color: var(--8am-grey, #5A5A5A);
            font-family: IBM Plex Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            /* 133.333% */
            letter-spacing: 0.36px;
        }

        .content {
            display: flex;
            gap: 12px;
            margin-bottom: 30px;

            .item {
                display: flex;
                padding: 8px 12px;
                align-items: center;
                gap: 12px;
                border-radius: 100px;
                background: var(--8am-lightgrey, #E9E9E9);

                .item-text {
                    color: var(--8am-black, #1D1D1F);
                    font-family: IBM Plex Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    /* 150% */
                }

                .item-close {
                    color: var(--8am-black, #1D1D1F);
                    margin-top: .3vw;
                    cursor: pointer;
                }
            }
        }

        .content-category {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: space-between;
            gap: 50px;

            .items {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(3, .1fr);
                gap: 24px;
                column-gap: 50px;

                .item {
                    cursor: pointer;
                }
            }

            .items:first-child {
                .item {
                    color: #000;
                    font-family: IBM Plex Sans;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 40px;
                    /* 142.857% */

                    // Hiển thị 1 dòng duy nhất
                    white-space: nowrap;

                }
            }

            .items:last-child {
                grid-template-rows: repeat(5, .1fr);
                row-gap: 16px;


                .item {
                    color: #000;
                    font-family: IBM Plex Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    /* 200% */
                }

            }
        }

        .content-category-source {
            justify-content: space-between;
            gap: 50px;

            .items {
                display: flex;
                gap: 24px;

                .item {
                    cursor: pointer;
                    color: #000;
                    font-family: IBM Plex Sans;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 40px;
                    /* 142.857% */

                    // Hiển thị 1 dòng duy nhất
                    white-space: nowrap;
                }
            }
        }
    }

    .container-menu.responsive {
        display: flex;
        flex-direction: column;
        gap: .5vw;

        .title {
            color: var(--8am-grey, #5A5A5A);
            font-family: IBM Plex Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            /* 133.333% */
            letter-spacing: 0.36px;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 1vw;

            .item {
                display: flex;
                padding: 8px 0px;
                justify-content: space-between;
                align-items: center;
                background-color: var(--white-8am);

                /* 150% */
                .item-text {
                    color: var(--8am-black, #1D1D1F);
                    font-family: IBM Plex Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    /* 150% */
                }

                .item-close {
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                    font-size: 14px;
                }
            }
        }
    }

    .icon-close {
        cursor: pointer;
    }

    .main-header-responsive {
        display: flex;
        background: rgba(246, 244, 238, 0.9);
        -webkit-backdrop-filter: blur(13px);
        backdrop-filter: blur(13px);
        justify-content: space-between;
        padding: 10px 10px 4px;
        position: fixed;
        width: 100%;
        z-index: 1000;

        .header-info-left {
            img {
                width: 30px;
                height: 30px;
                flex-shrink: 0;
                cursor: pointer;
            }
        }

        .header-info-center {
            cursor: pointer;
        }

        .header-info-right {
            .user-infor {
                svg{
                    height: 24px;
                    width: 24px;

                }

                img{
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    flex-shrink: 0;
                    cursor: pointer;
                }
            }

            img {
                width: 24px;
                height: 24px;
                flex-shrink: 0;
                cursor: pointer;

            }
        }
    }

    .main-header-responsive.hide-header {
        display: none;

    }

    .main-header-responsive.width-700 {
        background-color: var(--white-8am);

        .header-info-right {
            .user-infor {
                display: inline-flex;
                // height: 48px;
                padding: 0px 12px;
                align-items: center;
                gap: 4px;
                flex-shrink: 0;

                border-radius: 100px;
                border: 1px solid var(--8am-lightgrey, #E9E9E9);
                background: #FFF;

                color: var(--8am-black, #1D1D1F);
                font-family: IBM Plex Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px;
                /* 266.667% */
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: var(--black-8am);
                    color: var(--white-8am);
                }
            }
        }
    }

    .ant-select-dropdown {
        min-width: 931px !important;
        left: 10% !important;

        .ant-select-item-empty {
            padding: 0 !important;
        }
    }

    .user-dropdown-menu {
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

        .user-dropdown-item {
            padding: 10px 16px;
            font-size: 14px;
            color: #333333;
            transition: background-color 0.3s ease;
            display: flex;
            align-items: center;
            &:hover {
                background-color: #f5f5f5;
            }

            .anticon {
                margin-right: 8px;
                font-size: 16px;
            }

            span {
                font-family: IBM Plex Sans;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                /* 150% */
            }
        }
    }

    // Optional: Style the dropdown arrow
    .ant-dropdown-placement-bottomRight>.ant-dropdown-arrow {
        border-top-color: #ffffff;
        border-left-color: #ffffff;
    }

    // @media (max-width: 3840px) {
    //     .main-header {
    //         .header-info {
    //             // width: 2500px;

    //         }
    //     }
    // }

    // @media (max-width: 2560px) {
    //     .main-header {
    //         font-style: normal;
    //         font-family: IBM Plex Sans;
    //         position: fixed !important;
    //         width: 100%;
    //         background: var(--white-8am);
    //         z-index: 1000;
    //         gap: 1em;
    //         padding: .5vw 1vw;
    //         padding-top: 24px;

    //         .header-info {
    //             display: grid;
    //             grid-template-columns: .1fr 1fr .1fr;
    //             // display: flex;
    //             // justify-content: space-between;
    //             // align-items: center;
    //             margin: 0 auto;
    //             gap: 0;

    //             .header-left {
    //                 display: flex;
    //                 align-items: center;
    //                 gap: 1em;

    //                 .header-logo {
    //                     cursor: pointer;

    //                     // display: flex;
    //                     // align-items: center;
    //                     // gap: 1em;
    //                     // cursor: pointer;
    //                     // font-weight: 700;
    //                     // font-size: 2.5em;
    //                     // color: var(--orange-8am);
    //                     // margin-bottom: .2em;
    //                     img {
    //                         width: 68.022px;
    //                         height: 20px;
    //                     }
    //                 }

    //                 .pages {
    //                     display: flex;
    //                     align-items: center;
    //                     gap: 1em;

    //                     .page-item {
    //                         // margin: 1em;
    //                         font-family: IBM Plex Sans;
    //                         padding: .75em 1em;
    //                         color: var(--black-8am);
    //                         font-size: 16px;
    //                         font-style: normal;
    //                         font-weight: 500;
    //                         line-height: normal;
    //                         cursor: pointer;
    //                         transition: all 0.3s ease-in-out;
    //                         border-radius: 100px;
    //                         white-space: nowrap
    //                     }

    //                     .page-item:hover {
    //                         background-color: var(--lightgrey-8am);
    //                     }

    //                     .page-item.active {
    //                         color: var(--white-8am);
    //                         background-color: var(--black-8am);
    //                     }
    //                 }
    //             }

    //             .header-content-input {
    //                 position: relative;
    //                 display: flex;
    //                 align-items: center;
    //                 // margin: 0px 1vw;
    //                 font-family: IBM Plex Sans;

    //                 .ant-select {
    //                     width: 100%;

    //                     .ant-select-selector {
    //                         height: 45px;
    //                         border-radius: 50px;
    //                         z-index: 3;
    //                         font-weight: 500;
    //                         font-size: 12px;
    //                         line-height: 24px;
    //                         background-color: var(--lightgrey-8am);
    //                         border: none;
    //                         color: var(--black-8am);

    //                         .ant-select-selection-search {

    //                             .ant-select-selection-search-input {
    //                                 margin-left: 2.5vw;
    //                                 margin-right: 2vw;
    //                             }
    //                         }

    //                         .ant-select-selection-placeholder {
    //                             margin-left: 2.5vw;
    //                             margin-top: .3vw;
    //                             font-size: .7vw;
    //                         }
    //                     }

    //                     .ant-select-arrow {
    //                         left: 1vw;
    //                     }

    //                     .ant-select-clear {
    //                         background-color: var(--lightgrey-8am);
    //                         right: 1vw;
    //                     }

    //                 }
    //             }

    //             .header-right {
    //                 display: flex;
    //                 align-items: center;
    //                 gap: 1vw;

    //                 .menubtn {
    //                     background: linear-gradient(308.85deg, var(--main-color) 0%, var(--main-color) 100%);
    //                     border: none;
    //                     color: white;
    //                     transition: all 0.3s ease-in-out;
    //                 }

    //                 .menubtn:hover,
    //                 .menubtn:focus {
    //                     animation: pulse 1s;
    //                     box-shadow: 0 0 0 2em transparent;
    //                     background: linear-gradient(308.85deg, var(--main-color) 0%, var(--main-color) 100%);
    //                 }


    //                 .user-infor {
    //                     display: flex;
    //                     align-items: center;
    //                     gap: 1vw;
    //                     border-radius: 100px;
    //                     border: .1vw solid var(--lightgrey-8am);
    //                     padding: .75em 1em;
    //                     color: var(--black-8am);
    //                     font-size: 15px;
    //                     font-style: normal;
    //                     font-weight: 500;
    //                     cursor: pointer;
    //                     transition: all 0.3s ease-in-out;
    //                     white-space: nowrap;
    //                     font-family: IBM Plex Sans;
    //                 }

    //                 .user-infor:hover {
    //                     background-color: var(--black-8am);
    //                     color: var(--white-8am);
    //                 }
    //             }
    //         }

    //         .header-type-news {
    //             display: flex;
    //             align-items: center;
    //             margin: 0 auto;
    //             gap: 2em;

    //             .content {
    //                 font-weight: 400;
    //                 font-size: 1.25em;
    //                 text-align: center;
    //                 color: var(--text-small);
    //                 transition: all 0.3s ease-in-out;
    //                 border-bottom: 3px solid white;
    //                 cursor: pointer;

    //             }

    //             .content.active {
    //                 border-bottom: 3px solid var(--main-color);
    //                 color: var(--main-color);
    //             }
    //         }
    //     }
    // }

    @media (max-width: 1920px) {
        .main-header {
            .header-info {
                // width: 1600px;

                .header-left {
                    .header-logo {
                        // .ant-image {
                        //     height: 5em;
                        //     width: 13em;
                        // }

                        // .logo-text {
                        //     font-size: 2em;
                        //     margin-bottom: .3em;
                        // }
                    }
                }

                .header-content-input {
                    .search-input {
                        width: 50vw;
                        font-size: 1.25em;
                        padding-left: 3em;
                    }

                    .search-input::placeholder {}

                    .search-input:hover {}

                    .search-input:focus {}

                    .anticon {
                        font-size: 1.5em;
                    }

                    .anticon-search {}

                    .anticon-down {}

                    .ant-select {

                        .ant-select-selector {
                            display: flex;
                            align-items: center;
                            font-size: 1.25em;

                            .ant-select-selection-search {
                                display: flex;
                                align-items: center;

                                .ant-select-selection-search-input {
                                    // margin-left: 10px;
                                }
                            }

                            .ant-select-selection-placeholder {
                                margin-left: 2.5vw;
                                margin-top: 0px;
                            }
                        }

                        .ant-select-clear {
                            // right: 45px;
                        }
                    }
                }

                .header-right {
                    .menubtn {}

                    .menubtn:hover,
                    .menubtn:focus {}


                    .user-infor {
                        .icon-group {
                            .anticon {
                                font-size: 1.5em;
                            }
                        }
                    }
                }
            }

            hr {
                margin: .75em 0;
            }

            .header-type-news {
                width: 1600px;

                .content {
                    margin-top: 0;
                    font-size: 1.5em;
                }

                .content.active {}
            }
        }

        .ant-select-item {
            font-weight: 500;
            font-size: 1.25em;
            line-height: 2em;
            color: #696969;
        }
    }

    @media (max-width: 1720px) {
        .main-header {
            .header-info {
                // width: 1400px;

                .header-left {
                    .header-logo {
                        // .ant-image {
                        //     height: 4.5em;
                        //     width: 12em;
                        // }

                        // .logo-text {
                        //     font-size: 1.5em;
                        // }
                    }
                }

                .header-content-input {
                    .search-input {
                        width: 50vw;
                        font-size: 1.25em;
                        padding-left: 3em;
                    }

                    .search-input::placeholder {}

                    .search-input:hover {}

                    .search-input:focus {}

                    .anticon {
                        font-size: 1.5em;
                    }

                    .anticon-search {}

                    .anticon-down {}

                    .ant-select {


                        .ant-select-selector {
                            height: 2.75em;
                            font-size: 1.25em;

                            .ant-select-selection-search {
                                .ant-select-selection-search-input {
                                    // margin-left: 10px;
                                }
                            }
                        }

                        .ant-select-clear {
                            // right: 45px;
                        }
                    }
                }

                .header-right {
                    .menubtn {}

                    .menubtn:hover,
                    .menubtn:focus {}


                    .user-infor {
                        .icon-group {
                            .anticon {
                                font-size: 1.5em;
                            }
                        }
                    }
                }
            }


            .header-type-news {
                width: 1400px;

                .content {
                    font-size: 1.5em;
                }

                .content.active {}
            }
        }
    }

    @media (max-width: 1440px) {
        .main-header {
            .header-info {
                // width: 1200px;

                .header-left {
                    .header-logo {
                        // .ant-image {
                        //     height: 4em;
                        //     width: 11em;
                        // }

                        // .logo-text {
                        //     font-size: 1.5em;
                        // }
                    }

                    .pages {
                        .page-item {
                            font-size: 1vw;
                        }
                    }
                }

                .header-content-input {
                    .search-input {
                        width: 40vw;
                        font-size: 1.25em;
                    }

                    .search-input::placeholder {}

                    .search-input:hover {}

                    .search-input:focus {}

                    .anticon {
                        font-size: 1.5em;
                    }

                    .anticon-search {}

                    .anticon-down {}

                    .ant-select {

                        .ant-select-selector {
                            height: 2.5em;
                            font-size: 1.1em;

                            .ant-select-selection-search {
                                .ant-select-selection-search-input {
                                    // margin-left: 10px;
                                }
                            }
                        }

                        .ant-select-clear {
                            // right: 45px;

                            .anticon {
                                font-size: 1.1em;
                            }
                        }
                    }
                }

                .header-right {
                    .menubtn {}

                    .menubtn:hover,
                    .menubtn:focus {}


                    .user-infor {
                        font-size: 1vw;

                        .icon-group {
                            .anticon {
                                font-size: 1.25em;
                            }
                        }
                    }
                }
            }

            .header-type-news {
                width: 1200px;

                .content {
                    font-size: 1.3em;
                }

                .content.active {}
            }
        }

        .ant-select-item {
            font-weight: 500;
            font-size: 1.1em;
            line-height: 2em;
            color: #696969;
        }
    }

    @media (max-width: 1280px) {
        .main-header {
            padding-top: 10px;

            .header-info {
                width: 100%;
                padding: 0 1vw;

                .header-left {
                    .pages {
                        .page-item {
                            font-size: 1.2vw;
                        }
                    }

                    .header-logo {
                        // font-size: 2em;
                    }

                    .pages {
                        .page-item {
                            // margin: 1em;
                            padding: .5em;
                        }

                        .page-item.active {}
                    }
                }

                .header-content-input {

                    .ant-select {

                        .ant-select-selector {
                            height: 2.5em;
                            font-size: 1em;

                            .ant-select-selection-search {
                                .ant-select-selection-search-input {}
                            }

                            .ant-select-selection-placeholder {
                                // margin-left: 2.5em;
                                margin-top: 0px;
                                font-size: 1vw;
                            }
                        }

                        .ant-select-clear {
                            // right: 45px;

                            .anticon {
                                font-size: 1.1em;
                            }
                        }
                    }
                }

                .header-right {
                    .menubtn {
                        font-size: 1vw;
                    }

                    .menubtn:hover,
                    .menubtn:focus {}


                    .user-infor {
                        padding: .5em;
                        font-size: 1.2vw;

                        .icon-group {
                            .anticon {
                                font-size: 1.5em;
                            }
                        }

                        svg {
                            width: 1.5em;
                            height: 1.5em;
                        }
                    }
                }
            }

            .header-type-news {
                width: 980px;

                .content {
                    font-size: 1.2em;
                }

                .content.active {}
            }
        }

        .ant-select-item {
            font-weight: 500;
            font-size: 1em;
            line-height: 2em;
            color: #696969;
        }
    }

    @media (max-width: 1000px) {
        .main-header {
            .header-info {


                .header-left {

                    .header-logo {
                        img {
                            width: 4em;
                            height: 100%;
                        }

                        // .ant-image {
                        //     height: 3em;
                        //     width: 9em;
                        // }

                        // .logo-text {}
                    }
                }

                .header-content-input {
                    margin: 0;

                    .ant-select {

                        .ant-select-selector {
                            .ant-select-selection-search {
                                .ant-select-selection-search-input {
                                    font-size: 1.25vw;
                                    // margin-left: 10px;
                                }
                            }
                        }

                        .ant-select-clear {
                            right: 2vw;

                            .anticon {
                                font-size: 1.5vw;
                            }
                        }
                    }
                }

                .header-right {
                    .menubtn {}

                    .menubtn:hover,
                    .menubtn:focus {}


                    .user-infor {
                        .icon-group {
                            .anticon {
                                font-size: 1.25em;
                            }
                        }
                    }
                }
            }

            .header-type-news {
                width: 780px;
                margin-top: -.25em;

                .content {
                    font-size: 1.2em;
                }

                .content.active {}
            }
        }

        .ant-select-item {
            font-weight: 500;
            font-size: .9em;
            line-height: 2em;
            color: #696969;
        }

        .container-menu {
            display: flex;
            flex-direction: column;
            gap: .5vw;

            .title {
                color: var(--grey-8am);
                font-family: IBM Plex Sans;
                font-size: 1.5vw;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 0.36px;
            }

            .content {
                display: flex;
                gap: 1vw;

                .item {
                    display: flex;
                    align-items: center;
                    gap: .5vw;
                    padding: 0 .4vw;
                    border-radius: 100px;
                    font-size: 1vw;
                    background: var(--lightgrey-8am);
                    color: var(--black-8am);

                    .item-text {
                        font-family: IBM Plex Sans;
                        font-style: normal;
                        font-weight: 400;
                        /* 150% */
                    }

                    .item-close {
                        margin-top: .5vw;
                        cursor: pointer;
                    }
                }
            }

            .content-category {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1vw;
                padding: 1vw;

                .items {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: .5vw;

                    .item {
                        cursor: pointer;
                    }
                }

                .items:first-child {
                    .item {
                        color: var(--black-8am);
                        font-family: IBM Plex Sans;
                        font-size: 1.75vw;
                        font-style: normal;
                        font-weight: 500;
                    }
                }

                .items:last-child {
                    .item {
                        color: var(--black-8am);
                        font-family: IBM Plex Sans;
                        font-size: 1.25vw;
                        font-style: normal;
                        font-weight: 500;
                    }

                }
            }
        }
    }

    @media (max-width: 800px) {
        .main-header {

            .header-info {
                display: flex;
                justify-content: space-between;

                .header-left {
                    .header-logo {
                        // .ant-image {
                        //     height: 3em;
                        //     width: 8em;
                        // }

                        // .logo-text {}
                    }
                }

                .header-content-input {
                    display: none;

                    .search-input {
                        width: 35vw;
                        font-size: 1em;
                    }

                    .search-input::placeholder {
                        font-size: .8em;
                    }

                    .search-input:hover {}

                    .search-input:focus {}

                    .anticon {
                        font-size: 1.25em;
                    }

                    .anticon-search {}

                    .anticon-down {}
                }

                .header-right {
                    margin-right: 4vw;

                    .menubtn {
                        display: block;
                        position: absolute;
                        background-color: var(--black-8am);
                        right: .5em;
                        transition: all 0.3s ease-in-out;
                        height: 3vw;
                        width: 3vw;
                        font-size: 2vw;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }

                    .menubtn:hover,
                    .menubtn:focus {
                        animation: pulse 1s;
                        box-shadow: 0 0 0 2em transparent;
                        background-color: var(--black-8am);
                    }


                    .user-infor {
                        .icon-group {
                            .anticon {}
                        }
                    }
                }
            }

            .header-type-news {
                width: 580px;

                .content {
                    font-size: 1.1em;
                }

                .content.active {}
            }
        }

        .search-active {
            position: fixed;
            display: flex;
            align-items: center;
            gap: 1em;
            padding: 1em;
            padding-top: 0;
            width: 100%;
            top: 0;
            z-index: 1000;
            background-color: var(--white-8am);

            .search-input-content {
                position: relative;
                display: flex;
                align-items: center;
                margin: .5em;
                width: 100%;

                .ant-select {
                    width: 100%;

                    .ant-select-selector {
                        height: 2.5em;
                        border-radius: 50px;
                        z-index: 3;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                        background-color: var(--lightgrey-8am);
                        border: none;

                        .ant-select-selection-search {

                            .ant-select-selection-search-input {
                                // margin-left: 1.7em;
                            }
                        }

                        .ant-select-selection-placeholder {
                            // margin-left: 3em;
                        }
                    }

                    .ant-select-arrow {
                        // left: 1em;
                    }

                }
            }

            .anticon-arrow-left {
                cursor: pointer;
                border-radius: 50%;
                padding: 1vw;
                font-size: 1.5vw;
                border: .1vw solid var(--lightgrey-8am);
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: var(--black-8am);
                    color: var(--white-8am);
                }
            }


            .pages-responsive {
                display: none;
            }
        }
    }

    @media (max-width: 600px) {
        .main-header {
            .header-info {
                width: 100%;
                margin-left: .5em;
                // margin-top: 0em;

                .header-left {
                    .header-logo {

                        // font-size: 1.25em;
                        img {
                            width: 2em;
                            height: 100%;
                        }

                        .logo-text {}
                    }

                    .pages {
                        gap: 1vw;

                        .page-item {
                            // margin: 1em;
                            font-size: 1.5vw;
                            // padding: 0em;
                        }

                        .page-item.active {}
                    }
                }

                .header-content-input {
                    display: none;

                    .search-input {}

                    .search-input::placeholder {}

                    .search-input:hover {}

                    .search-input:focus {}

                    .anticon {}

                    .anticon-search {}

                    .anticon-down {}
                }

                .header-right {
                    margin-right: 5vw;

                    .menubtn {}

                    .menubtn:hover,
                    .menubtn:focus {}


                    .user-infor {
                        font-size: 1.5vw;

                        .icon-group {
                            .anticon {}
                        }
                    }
                }
            }

            .header-type-news {
                width: 380px;

                .content {
                    font-size: 1em;
                    white-space: nowrap;
                }
            }
        }
    }

    @media (max-width: 450px) {
        .main-header {
            padding-top: 0;

            .header-info {
                width: 100%;
                margin-left: .5em;
                // margin-top: 0em;

                .header-left {
                    .header-logo {
                        // font-size: 1.25em;

                        .logo-text {}
                    }

                    .pages {

                        .page-item {
                            // margin: 1em;
                            // padding: 0em;
                        }

                        .page-item.active {}
                    }
                }

                .header-content-input {
                    display: none;

                    .search-input {}

                    .search-input::placeholder {}

                    .search-input:hover {}

                    .search-input:focus {}

                    .anticon {}

                    .anticon-search {}

                    .anticon-down {}
                }

                .header-right {
                    margin-right: 1vw;

                    .menubtn {}

                    .menubtn:hover,
                    .menubtn:focus {}


                    .user-infor {

                        .icon-group {
                            .anticon {}
                        }
                    }
                }
            }

            .header-type-news {
                width: 380px;

                .content {
                    font-size: 1em;
                    white-space: nowrap;
                }
            }
        }

        .search-active {
            position: fixed;
            display: grid;
            grid-template-columns: 0.001fr 1fr;
            grid-template-rows: 0.001fr 1fr;
            align-items: start;
            gap: 1em;
            padding: 1em;
            padding-top: 0;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: 1000;
            background-color: var(--white-8am);
            user-select: none;

            .search-input-content {
                position: relative;
                display: flex;
                align-items: center;
                margin: .5em;
                width: 100%;
                grid-area: 1 / 2 / 2 / 3;

                .ant-select {
                    width: 100%;

                    .ant-select-selector {
                        height: 2.5em;
                        border-radius: 50px;
                        z-index: 3;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                        background-color: var(--lightgrey-8am);
                        border: none;

                        .ant-select-selection-search {

                            .ant-select-selection-search-input {
                                // margin-left: 1.7em;
                            }
                        }

                        .ant-select-selection-placeholder {
                            // margin-left: 3em;
                        }
                    }

                    .ant-select-arrow {
                        // left: 1em;
                    }

                }
            }

            .anticon-arrow-left {
                cursor: pointer;
                margin-top: 3vw;
                padding: 2vw;
                font-size: 2vw;
                grid-area: 1 / 1 / 2 / 2;
            }

            .pages-responsive {
                grid-area: 2 / 1 / 3 / 3;
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 1em;

                .page-item {
                    width: 100%;
                    text-align: center;
                    color: var(--black-8am);
                    font-size: 2em;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                }

                .page-item:hover {
                    color: var(--white-8am);
                    background-color: var(--black-8am);
                }
            }
        }
    }

    // @media (max-width: 300px) {
    //     .main-header {
    //         padding: .25em 1em;

    //         .header-info {
    //             .header-left {
    //                 .header-logo {
    //                     .logo-text {}
    //                 }
    //             }

    //             .header-content-input {
    //                 display: none;

    //                 .search-input {}

    //                 .search-input::placeholder {}

    //                 .search-input:hover {}

    //                 .search-input:focus {}

    //                 .anticon {}

    //                 .anticon-search {}

    //                 .anticon-down {}
    //             }

    //             .header-right {
    //                 .menubtn {}

    //                 .menubtn:hover,
    //                 .menubtn:focus {}


    //                 .user-infor {
    //                     display: none;

    //                     .icon-group {
    //                         .anticon {}
    //                     }
    //                 }
    //             }
    //         }
    //     }

    //     .ant-drawer {
    //         .ant-drawer-content-wrapper {
    //             width: 50vw !important;
    //         }

    //         .logo-text {
    //             display: none;
    //         }

    //         .ant-drawer-body {
    //             .header-content-input {
    //                 .anticon-down {
    //                     display: none;
    //                 }
    //             }
    //         }
    //     }
    // }