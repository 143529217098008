.footer-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    margin: 0 auto;
    padding-top: 3em;
    margin-top: 3em;
    margin-bottom: 3em;
    border-top: .1vw solid var(--black-8am);
    font-family: IBM Plex Sans;
    width: 100%;
    max-width: 1440px;
    padding-left: 10px;
    padding-right: 10px;

    .info-footer-main,
    .policy-security-footer-main {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        gap: 1em;

        .info-footer-main-item {
            display: flex;
            flex-direction: column;

            .info-title {
                color: var(--middlegrey-8am);
                font-family: IBM Plex Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;

                br {
                    margin-bottom: 1em;
                }
            }

            .info-content {
                color: var(--black-8am);
                font-family: IBM Plex Sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                display: flex;
                flex-direction: column;
                gap: 1vw;
                margin-top: 1vw;
            }
        }

        .info-footer-main-item:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
        }

        .info-footer-main-item:nth-child(2) {
            grid-area: 1 / 3 / 2 / 4;
        }

        .info-footer-main-item:nth-child(3) {
            grid-area: 1 / 4 / 2 / 5;
        }
    }

    .info-footer-main {
        margin: 40px 0;
    }

    .policy-security-footer-main {
        border-top: .1vw solid var(--lightgrey-8am);
        padding: 40px 0px 20px;

        .info-footer-main-item {
            .info-content {
                color: var(--middlegrey-8am);
                font-family: IBM Plex Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                margin-top: 1vw;
            }
        }

    }
}

.footer-main-responsive {
    background: var(--8am-bg-beige, #F6F4EE);
    border-top: 1px solid #1D1D1F;
    padding: 40px 0 40px;

    .logo-footer-main {
        margin-bottom: 52px;
        margin: 0 20px;

        img {
            width: 81.626px;
            height: 24px;
            flex-shrink: 0;
        }
    }

    .info-footer-main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 50px 20px 20px;

        .info-footer-main-item {

            .info-title {
                color: var(--8am-middlegrey, #86868B);
                font-family: IBM Plex Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 171.429% */
            }

            .info-content {
                color: var(--8am-black, #1D1D1F);
                font-family: IBM Plex Sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;

                /* 160% */
                span {
                    // Thêm dấu gạch dưới bên dưới
                    text-decoration: underline;
                    color: var(--8am-black, #1D1D1F);
                    font-family: IBM Plex Sans;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                }
            }


        }

        .info-footer-main-item:last-child {
            margin-top: 30px;
        }
    }

    .policy-security-footer-main {
        border-top: 1px solid #86868B;
        padding: 32px 20px 0;
        display: flex;
        flex-direction: column;
        gap: 32px;

        .info-footer-main-item {
            color: var(--8am-middlegrey, #86868B);
            font-family: IBM Plex Sans;
            font-size: 14px;
            font-style: normal;
            // font-weight: 500;
            line-height: 22px;
            /* 157.143% */
            display: flex;
            flex-direction: column;
            gap: 12px;

            .info-content {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
        }
    }
}

@media (max-width: 1440px) {
    .footer-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1em;
        margin: 0 auto;
        padding-top: 3em;
        margin-top: 3em;
        margin-bottom: 3em;
        border-top: .1vw solid var(--black-8am);
        font-family: IBM Plex Sans;
        width: 100%;
        max-width: 1284px;

        .info-footer-main,
        .policy-security-footer-main {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;
            gap: 1em;

            .info-footer-main-item {
                display: flex;
                flex-direction: column;

                .info-title {
                    color: var(--middlegrey-8am);
                    font-family: IBM Plex Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;

                    br {
                        margin-bottom: 1em;
                    }
                }

                .info-content {
                    color: var(--black-8am);
                    font-family: IBM Plex Sans;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    display: flex;
                    flex-direction: column;
                    gap: 1vw;
                    margin-top: 1vw;
                }
            }

            .info-footer-main-item:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
            }

            .info-footer-main-item:nth-child(2) {
                grid-area: 1 / 3 / 2 / 4;
            }

            .info-footer-main-item:nth-child(3) {
                grid-area: 1 / 4 / 2 / 5;
            }
        }

        .policy-security-footer-main {
            border-top: .1vw solid var(--lightgrey-8am);
            padding: 1em 0px;

            .info-footer-main-item {
                .info-content {
                    color: var(--middlegrey-8am);
                    font-family: IBM Plex Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    margin-top: 1vw;
                }
            }

        }
    }
}

@media (max-width: 1100px) {
    .footer-main {
        width: 100%;
        margin: 0 auto;
        gap: 1vw;
        max-width: 720px;
        padding-bottom: 40px;
        margin-top: 30px;

        img {
            width: 120.003px;
            height: 35.282px;
            flex-shrink: 0;
        }

        .info-footer-main {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 40px;

            .info-footer-main-item:nth-child(1) {
                grid-area: 1 / 1 / 3 / 2;
            }

            .info-footer-main-item:nth-child(2) {
                grid-area: 1 / 2 / 2 / 3;
                display: flex;
                justify-content: end;
            }

            .info-footer-main-item:nth-child(3) {
                grid-area: 2 / 2 / 3 / 3;
            }
        }

        .info-footer-main,
        .policy-security-footer-main {

            .info-footer-main-item {
                .info-title {
                    color: var(--8am-middlegrey, #86868B);
                    font-family: IBM Plex Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;

                    /* 171.429% */
                }

                .info-content {
                    color: var(--8am-black, #1D1D1F);
                    font-family: IBM Plex Sans;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    /* 160% */
                }
            }
        }

        .policy-security-footer-main {
            grid-template-columns: repeat(4, 2fr);
            grid-template-rows: 1fr;

            .info-footer-main-item {
                .info-content {
                    color: var(--8am-middlegrey, #86868B);
                    font-family: IBM Plex Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                }
            }

            .info-footer-main-item:first-child {
                grid-area: 1 / 1 / 2 / 3;
            }
        }
    }
}