:root {
    --orange-8am: #FF3C03;

    --black-8am: #1D1D1F;
    --white-8am: #FFFFFF;
    --lightwhite-8am: #FBFAFA;
    --grey-8am: #5A5A5A;
    --blue-8am: #3F72E3;
    --middlegrey-8am: #86868B;
    --middlegrey-hover-8am: #e1e1e1;
    --lightgrey-8am: #E9E9E9;
    --lightblue-8am: #d3f0fe;
    --lightgreen-8am: #a7dc73;
    --lightyellow-8am: #ffee87;
    --lightorange-8am: #ef7e4e;
    --bg-beige-8am: #F6F4EE;

    --icon-sad: #FF4D4F;
    --icon-happy: #52C41A;

}